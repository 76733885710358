import { SET_CURRENT, SET_FIELD, SET_SERVICES } from '../actions/types';

const initialState = {
  current: 0,
  data: {
    firstname: ``,
    lastname: ``,
    email: ``,
    phone: ``,
    passport: ``,
    date: ``,
    services: [],
    informations: ``,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case SET_FIELD:
      console.log(action);
      return {
        ...state,
        data: {
          ...state.data,
          [action.name]: action.value,
        },
      };
    case SET_SERVICES:
      return {
        ...state,
        data: {
          ...state.data,
          services: action.payload,
        },
      };
    default:
      return state;
  }
}
