import { SWITCH_LANGUAGE } from '../actions/types';

const initialState = {
  locale: `en`,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.locale,
      };
    default:
      return state;
  }
}
