import { SWITCH_LANGUAGE } from './src/actions/types';
import wrapWithProvider from './wrap-with-provider';

import Store from './src/store';

export const onClientEntry = () => {
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`);
  }

  const { location } = window;

  if (location.pathname.toLocaleLowerCase().includes(`en`)) {
    Store.dispatch({
      type: SWITCH_LANGUAGE,
      locale: `en`,
    });
  } else if (location.pathname.toLocaleLowerCase().includes(`en`)) {
    Store.dispatch({
      type: SWITCH_LANGUAGE,
      locale: `fr`,
    });
  } else {
    Store.dispatch({
      type: SWITCH_LANGUAGE,
      locale: `fr`,
    });
  }
};

export const wrapRootElement = wrapWithProvider;
