// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-booking-en-jsx": () => import("./../src/pages/booking.en.jsx" /* webpackChunkName: "component---src-pages-booking-en-jsx" */),
  "component---src-pages-contact-en-jsx": () => import("./../src/pages/contact.en.jsx" /* webpackChunkName: "component---src-pages-contact-en-jsx" */),
  "component---src-pages-contact-fr-jsx": () => import("./../src/pages/contact.fr.jsx" /* webpackChunkName: "component---src-pages-contact-fr-jsx" */),
  "component---src-pages-escales-fr-jsx": () => import("./../src/pages/escales.fr.jsx" /* webpackChunkName: "component---src-pages-escales-fr-jsx" */),
  "component---src-pages-index-en-jsx": () => import("./../src/pages/index.en.jsx" /* webpackChunkName: "component---src-pages-index-en-jsx" */),
  "component---src-pages-index-fr-jsx": () => import("./../src/pages/index.fr.jsx" /* webpackChunkName: "component---src-pages-index-fr-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pricing-en-jsx": () => import("./../src/pages/pricing.en.jsx" /* webpackChunkName: "component---src-pages-pricing-en-jsx" */),
  "component---src-pages-reservation-fr-jsx": () => import("./../src/pages/reservation.fr.jsx" /* webpackChunkName: "component---src-pages-reservation-fr-jsx" */),
  "component---src-pages-services-en-jsx": () => import("./../src/pages/services.en.jsx" /* webpackChunkName: "component---src-pages-services-en-jsx" */),
  "component---src-pages-services-fr-jsx": () => import("./../src/pages/services.fr.jsx" /* webpackChunkName: "component---src-pages-services-fr-jsx" */),
  "component---src-pages-stopovers-en-jsx": () => import("./../src/pages/stopovers.en.jsx" /* webpackChunkName: "component---src-pages-stopovers-en-jsx" */),
  "component---src-pages-tarification-fr-jsx": () => import("./../src/pages/tarification.fr.jsx" /* webpackChunkName: "component---src-pages-tarification-fr-jsx" */)
}

